export interface MenuItem {
    id?: number;
    name?: string;
    categoryname?: string;
    label?: string;
    icon?: string;
    link?: string;
    subItems?: any;
    isTitle?: boolean;
    badge?: any;
    parentId?: number;
    isLayout?: boolean;
    visible?: boolean;
}

export const MENU: MenuItem[] = [
    {
        id: 1,
        name: 'Dashboard',
        categoryname: "StandardDashboard",
        label: 'MENUITEMS.DASHBOARD.TEXT',
        icon: 'bxs-bar-chart-alt-2',
        link: '/crm/dashboard',
    },
    {
        id: 2,
        name: 'Setup',
        categoryname: "Setup",
        label: 'MENUITEMS.SETUP.TEXT',
        icon: 'bx-cog',
        subItems: [
            {
                id: 2.1,
                name: 'Activities',
                categoryname: "Activities",
                label: 'MENUITEMS.SETUP.LIST.ACTIVITIES',
                link: '/crm/setup/activities',
                parentId: 2
            },
            {
                id: 2.2,
                name: 'Statues',
                categoryname: "Statues",
                label: 'MENUITEMS.SETUP.LIST.STATUES',
                link: '/crm/setup/statues',
                parentId: 2
            },
            {
                id: 2.3,
                name: 'Services',
                categoryname: "Services",
                label: 'MENUITEMS.SETUP.LIST.SERVICES',
                link: '/crm/setup/services',
                parentId: 2
            },
            {
                id: 2.4,
                name: 'General',
                categoryname: "General",
                label: 'MENUITEMS.SETUP.LIST.GENERAL.TEXT',
                parentId: 2,
                subItems: [
                    {
                        id: 2.41,
                        name: 'AddressBook',
                        categoryname: "General",
                        label: 'MENUITEMS.SETUP.LIST.GENERAL.LIST.ADDRESSBOOK',
                        link: '/crm/setup/general/addressbook',
                        parentId: 2.4,
                    }
                ]
            }
        ]
    },
    {
        id: 3,
        name: 'SystemConfiguration',
        categoryname: "SystemConfiguration",
        label: 'MENUITEMS.SYSTEMCONFIG.TEXT',
        icon: 'bx-table',
        subItems: [
            {
                id: 3.1,
                name: 'TAT',
                categoryname: "TAT",
                label: 'MENUITEMS.SYSTEMCONFIG.LIST.TAT',
                link: '/crm/systemconfiguration/tat',
                parentId: 3,
            },
            {
                id: 3.2,
                name: 'Templates',
                categoryname: "Templates",
                label: 'MENUITEMS.SYSTEMCONFIG.LIST.TEMPLATES',
                link: '/crm/systemconfiguration/templates',
                parentId: 3,
            }
        ]
    },
    {
        id: 4,
        name: 'ServiceRenewal',
        categoryname: "ServiceRenewal",
        label: 'MENUITEMS.SERVICERENEWAL.TEXT',
        icon: 'bx-wrench',
        link: '/crm/servicerenewal'
    },
    {
        id: 5,
        name: 'Ticketing',
        categoryname: "Ticketing",
        label: 'MENUITEMS.TICKETING.TEXT',
        icon: 'bx-detail',
        link: '/crm/ticketing'
    },
    {
        id: 6,
        name: 'TaskManagement',
        categoryname: 'TaskManagement',
        label: 'MENUITEMS.TASKMANAGEMENT.TEXT',
        icon: 'bx-task',
        link: '/crm/taskmanagement',
    },
    {
        id: 7,
        name: 'Reports',
        categoryname: 'Reports',
        label: 'MENUITEMS.REPORTS.TEXT',
        icon: 'bxs-report',
        link: '/crm/reports',
    },
    {
        id: 8,
        name: 'UserManagement',
        categoryname: 'UserManagement',
        label: 'MENUITEMS.USERMANAGEMENT.TEXT',
        icon: 'bxs-user-detail',
        subItems: [
            {
                id: 8.1,
                name: 'Roles',
                categoryname: 'Roles',
                label: 'MENUITEMS.USERMANAGEMENT.LIST.ROLES',
                link: '/crm/roles',
                parentId: 8
            },
            {
                id: 8.2,
                name: 'Users',
                categoryname: 'Users',
                label: 'MENUITEMS.USERMANAGEMENT.LIST.USERS',
                link: '/crm/users',
                parentId: 8
            },
        ]
    }
   

];
