import { inject } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AppCoreService } from './app-core.service';

export const AuthGuardService = () => {
  const appCoreService = inject(AppCoreService);
  const router = inject(Router);

  // console.log('authGuard#canActivate called');
  // console.log(appCoreService.isUserAuthenticated())
  if (appCoreService.isUserAuthenticated()) {
    return true;
  }

  // Redirect to the login page
  return router.parseUrl('/portal/account/login');
};