import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { LayoutComponent } from './components/layout/layout.component';
import { Page404Component } from './components/page404/page404.component';
import { AuthGuardService } from './services/auth-guard.service'; 

const routes: Routes = [
  // { path: '', redirectTo: 'account/login', pathMatch: 'full' },
  { path: 'account', loadChildren: () => import('./modules/account/account.module').then(m => m.AccountModule) },
  { path: '', component: LayoutComponent, loadChildren: () => import('./modules/home/home.module').then(hm => hm.HomeModule), canActivate: [AuthGuardService] },
  // {
  //   path: 'forgot-password',
  //   loadChildren: () => import('./modules/forgot-password/forgot-password.module').then(l => l.ForgotPasswordModule)
  // },
  // {
  //   path: ':name',
  //   loadChildren: () => import('./modules/home/home.module').then(hm => hm.HomeModule)
  // },
  { path: '**', component: Page404Component },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    enableTracing: false,
    preloadingStrategy: PreloadAllModules
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
