import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpEventType, HttpHeaders, HttpRequest, HttpBackend } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';


@Injectable()
export class HttpHandlerService {

  constructor(private http: HttpClient, private handler: HttpBackend) { }

  request(httpRequest: any, encryption: boolean = true, byPass = false): Observable<any> {
    if (byPass) {
      // to bypass the http interceptor call for guest
      const http1 = new HttpClient(this.handler);
      return http1.request(httpRequest).pipe(
        map((response: HttpEvent<any>) => this.parseResponse(response)), shareReplay({ bufferSize: 1, refCount: true }));
    } else {
      if (encryption) {
        return this.http.request(httpRequest).pipe(
          map((response: HttpEvent<any>) => this.parseResponseWithEncryption(response)), shareReplay({ bufferSize: 1, refCount: true }));

      } else {
        return this.http.request(httpRequest).pipe(
          map((response: HttpEvent<any>) => this.parseResponse(response)), shareReplay({ bufferSize: 1, refCount: true })
        );
      }
    }

  }

  parseResponseWithEncryption(httpEvent: HttpEvent<any>): any {
    switch (httpEvent.type) {
      case HttpEventType.Sent:
        break;
      case HttpEventType.ResponseHeader:
        break;
      case HttpEventType.DownloadProgress:
        break;
      case HttpEventType.Response:
        const body = httpEvent.body;
        const base64Url = body.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map(c => {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        return JSON.parse(jsonPayload);

    }
  }

  parseResponse(httpEvent: HttpEvent<any>): any {
    switch (httpEvent.type) {
      case HttpEventType.Sent:
        break;
      case HttpEventType.ResponseHeader:
        break;
      case HttpEventType.DownloadProgress:
        break;
      case HttpEventType.Response:
        return httpEvent.body;
    }
  }

  prepareRequest(
    method: any,
    url: any,
    data?: any,
    token: any = null,
    params: URLSearchParams = new URLSearchParams(),
    headers: Headers = new Headers(),
    isFormData: boolean = false,
    isUrlParams: boolean = false,
    reportProgress: boolean = false
  ): HttpRequest<any>
  {
    const headerSettings: { [name: string]: string | string[] } = {};
    headerSettings['Content-Type'] = 'application/json';
    // if (headers != null && !isFormData) {
    //   headerSettings['Content-Type'] = 'application/json';
    // }
    if (Object.keys(headers).length) {
      headers.forEach((k, v) => {
        headerSettings[k] = v;
      });
    }
    

    if (token !== null){
      const authorization = 'Authorization';
      headerSettings[authorization] = 'Bearer ' + token;
    }

    let httpHeaders = new HttpHeaders(headerSettings);
    const httpInit = {
      headers: httpHeaders,
      search: params
    };
    
    return new HttpRequest(method, url, data, httpInit);
  }
}
