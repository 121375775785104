<!-- ========== Left Sidebar Start ========== -->

<ng-template #contentTemplate>

    <!--- Sidemenu -->
    <div id="sidebar-menu">
      <!-- Left Menu Start -->
      <ul class="metismenu list-unstyled" id="side-menu" #sideMenu>
        <!-- {{menuItems}} -->
        <ng-container *ngFor="let item of menuItems">
          <ng-container *ngIf="item.visible">
            <li class="menu-title" *ngIf="item.isTitle">{{ item.label | translate }}</li>
            <li *ngIf="!item.isTitle && !item.isLayout">
              <a *ngIf="hasItems(item)" href="javascript:void(0);" class="is-parent"
                [ngClass]="{'has-arrow': !item.badge, 'has-dropdown': item.badge}">
                <i class="bx {{item.icon}}" *ngIf="item.icon"></i>
                <span> {{ item.label | translate }}</span>
                <span class="badge rounded-pill float-end bg-{{item.badge.variant}}" *ngIf="item.badge">{{item.badge.text
                  |
                  translate}}</span>
              </a>
  
              <a [routerLink]="item.link" *ngIf="!hasItems(item)" class="side-nav-link-ref" routerLinkActive="active">
                <i class="bx {{ item.icon }}" *ngIf="item.icon"></i>
                <span> {{ item.label | translate }}</span>
                <span class="badge rounded-pill bg-{{item.badge.variant}} float-end" *ngIf="item.badge">{{item.badge.text
                  |
                  translate}}</span>
              </a>
  
              <ul *ngIf="hasItems(item)" class="sub-menu" aria-expanded="false">
                <li *ngFor="let subitem of item.subItems">
                  <ng-container *ngIf="subitem.visible">
                    <a [routerLink]="subitem.link" *ngIf="!hasItems(subitem)" class="side-nav-link-ref"
                      [attr.data-parent]="subitem.parentId" routerLinkActive="active">
                      {{ subitem.label | translate}}
                    </a>
                    <a *ngIf="hasItems(subitem)" class="side-nav-link-a-ref has-arrow" href="javascript:void(0);"
                      [attr.data-parent]="subitem.parentId">
                      {{ subitem.label | translate}}
                    </a>
                    <ul *ngIf="hasItems(subitem)" class="sub-menu mm-collapse" aria-expanded="false">
                      <li *ngFor="let subSubitem of subitem.subItems">
                        <ng-container *ngIf="subSubitem.visible">
                          <a [attr.data-parent]="subSubitem.parentId" [routerLink]="subSubitem.link"
                            routerLinkActive="active" class="side-nav-link-ref">
                            {{ subSubitem.label | translate }}
                          </a>
                        </ng-container>
                      </li>
                    </ul>
                  </ng-container>
  
                </li>
              </ul>
            </li>
          </ng-container> 
  
        </ng-container>
      </ul>
    </div>
  </ng-template>
  <!-- Sidebar --> 
  
  <div class="vertical-menu">
    <ngx-simplebar class="h-100" #componentRef>
      <div *ngIf="!isCondensed">
        <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
      </div>
      <div *ngIf="isCondensed">
        <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
      </div>
    </ngx-simplebar>
  </div>
  <!-- Left Sidebar End -->