import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { LayoutComponent } from './portal/components/layout/layout.component';
import { Page404Component } from './portal/components/page404/page404.component';
import { AuthGuardService } from './portal/services/auth-guard.service'; 
import { CrmlayoutComponent } from './crm/components/crmlayout/crmlayout.component';

const routes: Routes = [
  { path: '', redirectTo: 'portal', pathMatch: 'full' },
  { path: 'portal/account', loadChildren: () => import('./portal/modules/account/account.module').then(m => m.AccountModule) },
  { path: 'portal', component: LayoutComponent, loadChildren: () => import('./portal/modules/home/home.module').then(hm => hm.HomeModule), canActivate: [AuthGuardService] },
  { path: 'crm', component: CrmlayoutComponent,loadChildren:()=> import ('./crm/modules/home/home.module').then(hm=>hm.HomeModule),canActivate: [AuthGuardService]},
  // { path: 'warehouse', component: LayoutComponent, loadChildren: () => import('./warehouse/modules/home.module').then(hm => hm.HomeModule), canActivate: [AuthGuardService] },
  // {
  //   path: 'forgot-password',
  //   loadChildren: () => import('./modules/forgot-password/forgot-password.module').then(l => l.ForgotPasswordModule)
  // },
  // {
  //   path: ':name',
  //   loadChildren: () => import('./modules/home/home.module').then(hm => hm.HomeModule)
  // },
  { path: '**', component: Page404Component },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    enableTracing: false,
    preloadingStrategy: PreloadAllModules
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
