
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpHandlerService } from './http-handler.service';
import { environment } from 'src/environments/environment'; 
import { TokenData } from '../models/user.data-object'; 

@Injectable({
  providedIn: 'root'
})
export class AppTokenService {
  // _httpHandler: HttpHandlerService;
  _apiUrl = environment.apiUrl;
  _guestTokenEndPoint = 'v1/token/guest';
  _userTokenEndPoint = 'v1/token/user';
  _refreshTokenEndPoint = 'refreshtoken';
  tokenData = new BehaviorSubject<TokenData>(null);
  isTokenExist = new BehaviorSubject<boolean>(this.hasToken());
  private internal_token_data: TokenData;
  tokenizedResponse = environment.apiTokenizationEnabled;

  constructor(private httpHandler: HttpHandlerService) {

  }

  private hasToken(): boolean {
    const saved_token = !!localStorage.getItem('token');
    if (saved_token) {
      const tokenData: TokenData = JSON.parse(localStorage.getItem('token'));
      if (tokenData.accesstoken !== '') {
        return true;
      }
    }
    return false;
  }

  public CheckToken(): Observable<boolean> {
    return this.isTokenExist.asObservable();
  }

  // remove the token - usually after user logout
  public removeToken(): void {
    localStorage.removeItem('token');
    const _tokenData: TokenData = {
      accesstoken: '',
      refreshtoken: '',
      type: ''
    };
    this.tokenData.next(_tokenData);
    this.isTokenExist.next(false);
  }

  // Abstract setter and getter token methods
  public SetToken(_tokenData: TokenData): void {
    localStorage.setItem('token', JSON.stringify(_tokenData));
    this.tokenData.next(_tokenData);
    this.isTokenExist.next(true);

  }

  public GetToken(): string {
    const tokenData: TokenData = JSON.parse(localStorage.getItem('token'));
    return tokenData.accesstoken;
  }

  public GetRefreshToken(): string {
    const tokenData: TokenData = JSON.parse(localStorage.getItem('token'));
    return tokenData.refreshtoken;
  }


  public DecodeToken(token): any {
    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(atob(base64).split('').map(c => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      return JSON.parse(jsonPayload);
    } catch (e) {
      console.log(e);
    }
  }

  GetTokenData(): string {
    // console.log(this.tokenData);
    // console.log(this.tokenData.value);
    this.internal_token_data = this.tokenData.value;
    return this.internal_token_data.accesstoken;
  }

  // Token methods
  public CreateToken(isGuestToken = true): Observable<any> {
    let request_options;
    if (isGuestToken) {
      // if the token request is made for Guest
      const full_api_url = this._apiUrl + this._guestTokenEndPoint;
      request_options = this.httpHandler.prepareRequest(
        'GET',
        full_api_url,
        {}
      );
      return this.httpHandler.request(request_options, this.tokenizedResponse);
    } else {
      // if the token request is made for User
      const full_api_url = this._apiUrl + this._userTokenEndPoint;
      request_options = this.httpHandler.prepareRequest(
        'GET',
        full_api_url,
        {}
      );
      return this.httpHandler.request(request_options);
    }
  }

  public CallRefreshTokenURL(token: string): Observable<any> {
    let requestObj = {
      refreshToken: token
    };
    const full_api_url = this._apiUrl + this._refreshTokenEndPoint;
    let request_options = this.httpHandler.prepareRequest(
      'POST',
      full_api_url,
      requestObj
    );
    return this.httpHandler.request(request_options, this.tokenizedResponse);
  }
}
