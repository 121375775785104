import { Component } from '@angular/core';

@Component({
  selector: 'app-crmlayout',
  templateUrl: './crmlayout.component.html',
  styleUrls: ['./crmlayout.component.scss']
})
export class CrmlayoutComponent {

}
