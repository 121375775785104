import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppTokenService } from './app-token.service';
import { HttpHandlerService } from './http-handler.service';

@Injectable()
export class LoginService {
  _httpHandler: HttpHandlerService;
  _apiUrl = environment.apiUrl;
  _authenticateUserEndPoint = 'login';
  _logoutUserEndPoint = 'logout';
  _forgotPasswordEndPoint = 'forgotpassword';
  _updatePasswordEndPoint = 'updatepassword';
  _verifyOTPEndPoint = 'verifyotp';
  _resendOTPEndPoint = 'resendotp';
  _resetPasswordEndPoint = 'resetpassword'

  constructor(private httpHandler: HttpHandlerService, private appTokenService: AppTokenService) {
    this._httpHandler = httpHandler;
  }

  // This function is used for whether user is present or not in the system
  public AuthenticateUser(params: any): Observable<any> {
    let full_api_url = this._apiUrl + this._authenticateUserEndPoint;
    if (params.mode === 'email') {
      full_api_url = full_api_url + `?m=email`;
    }

    const request_options = this._httpHandler.prepareRequest(
      'POST',
      full_api_url,
      params
    );
    return this._httpHandler.request(request_options, this.appTokenService.tokenizedResponse, true);
  }

  public LogoutUser(requestObj): Observable<any> {
    const full_api_url = this._apiUrl + this._logoutUserEndPoint;
    const request_options = this._httpHandler.prepareRequest(
      'POST',
      full_api_url,
      requestObj
    );
    return this._httpHandler.request(request_options, this.appTokenService.tokenizedResponse, true);
  }

  public ForgotPassword(requestObj): Observable<any> {
    const full_api_url = this._apiUrl + this._forgotPasswordEndPoint;
    const request_options = this._httpHandler.prepareRequest(
      'POST',
      full_api_url,
      requestObj
    );
    return this._httpHandler.request(request_options, this.appTokenService.tokenizedResponse, true);
  }

  public VerifyOtp(requestObj): Observable<any> {
    const full_api_url = this._apiUrl + this._verifyOTPEndPoint;
    const request_options = this._httpHandler.prepareRequest(
      'POST',
      full_api_url,
      requestObj
    );
    return this._httpHandler.request(request_options, this.appTokenService.tokenizedResponse, true);
  }

  public UpdatePassword(requestObj): Observable<any> {
    const full_api_url = this._apiUrl + this._updatePasswordEndPoint;
    const request_options = this._httpHandler.prepareRequest(
      'PUT',
      full_api_url,
      requestObj
    );
    return this._httpHandler.request(request_options, this.appTokenService.tokenizedResponse, true);
  }

  public resendOTP(requestObj): Observable<any> {
    const full_api_url = this._apiUrl + this._resendOTPEndPoint;
    const request_options = this._httpHandler.prepareRequest(
      'POST',
      full_api_url,
      requestObj
    );
    return this._httpHandler.request(request_options, this.appTokenService.tokenizedResponse, true);
  }

  public ResetPassword(requestObj): Observable<any> {
    const full_api_url = this._apiUrl + this._resetPasswordEndPoint;
    const request_options = this._httpHandler.prepareRequest(
      'PUT',
      full_api_url,
      requestObj
    );
    return this._httpHandler.request(request_options, this.appTokenService.tokenizedResponse, true);
  }
}
