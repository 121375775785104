
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError, EMPTY, of, BehaviorSubject, NEVER } from 'rxjs';
import { AppTokenService } from './app-token.service';
import { catchError, filter, finalize, map, switchMap, take, tap } from 'rxjs/operators';
// import { LoginService } from './login.service';
import { TokenData } from '../models/user.data-object'; 
const AUTH_TOKEN_HEADER_KEY = 'Authorization';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  // private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private refreshTokenSubject = new BehaviorSubject(null);

  constructor(private appTokenService: AppTokenService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authReq = request;
    const token_data = this.appTokenService.GetToken();
    
    if (token_data != null) {
      authReq = this.addTokenHeader(request, token_data);
    }
    // console.log('interceptor');
    // console.log(token_data);
    // request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token_data) });
    
    return next.handle(authReq).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse &&
            !authReq.url.includes('login') && !authReq.url.includes('refreshtoken')
            && error.status === 401) {
        
          // console.log('ERROR 401');
          return this.handle401Error(authReq, next);
        }
        
        return of(new HttpResponse({ body: error }));
      })
    );

    // //console.log(request);
    // return next.handle(request).pipe(map((event: HttpEvent<any>) => {
    //   return event;
    // }),
    //   catchError((error: any) => {
    //     // console.log(error);
    //     if (error.status === 0 || error.status === 401) {
    //       // console.log('## ERROR ## ', error.status);
    //       this.auth.removeToken();
    //     }
    //     // else if (error.status === 400) {
    //     //   console.log('logging error from interceptor');
    //     //   console.log('error ', error);
    //     // }
    //     // return throwError(error);
    //     return of(new HttpResponse({ body: error }));
    //   })
    // );
  }

  private addTokenHeader(request: HttpRequest<any>, token?: string) {
    if (!token) {
      return request;
    }
    return request.clone({ headers: request.headers.set(AUTH_TOKEN_HEADER_KEY, `Bearer ${token}`) });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      const refreshtoken = this.appTokenService.GetRefreshToken();
      
      if (refreshtoken) {

        return this.appTokenService.CallRefreshTokenURL(refreshtoken).pipe(
          filter(x => x !== undefined),
          switchMap((token: any) => {
            this.isRefreshing = false;
            if (token) {
              const newToken: TokenData = {
                accesstoken: token.accesstoken,
                refreshtoken: token.refreshtoken,
                type: 'user'
              };
              this.appTokenService.SetToken(newToken);
              this.refreshTokenSubject.next(token.accesstoken);
              return next.handle(this.addTokenHeader(request, token?.accesstoken));
            }
            this.appTokenService.removeToken();
            
            
          }),
          catchError((err) => {
            // console.log('ERROR while calling refresh', err);
            this.isRefreshing = false;
            this.appTokenService.removeToken();
            return of(new HttpResponse({ body: err }));
          })
        );
      }
        
    }

    return this.refreshTokenSubject.pipe(
      filter(token => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addTokenHeader(request, token)))
    );
  }

}
