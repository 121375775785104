import { AppTokenService } from './services/app-token.service'; 
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { AppCoreService } from './services/app-core.service'; 
import { NgxUiLoaderService } from 'ngx-ui-loader';
// import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'xpresion-customer-portal';
  isLoggedIn = false;

  constructor(
    private appCoreService: AppCoreService,
    private appTokenService: AppTokenService,
    private router: Router,
    private ngxLoaderService: NgxUiLoaderService,
  ) {

  }

  ngOnInit(): void {
    // check if user is logged in or not
    // this.appCoreService.CheckUserLoggedIn().subscribe(res => {
    //   if (!res) {
    //     this.isLoggedIn = false;
    //     document.querySelector('body').classList.add('login-page');
    //     this.router.navigate(['/login']);
    //   } else {
    //     document.querySelector('body').classList.remove('login-page');
    //     this.isLoggedIn = true;
    //   }
    // });
    const userData: any = localStorage.getItem('ud');
    const data = typeof userData === 'object' ? userData : JSON.parse(userData)
    if (data?.metaInfo?.resetpassword) {
      this.router.navigate(['/account/reset-password']);
    }
    
    this.appTokenService.tokenData.subscribe(t => {
      if (t?.accesstoken === '') {
        // console.log(t);
        // perform log out
        this.appCoreService.removeAllFromLS();
        this.appCoreService.RemoveUserData();
        this.isLoggedIn = false;
        this.appCoreService.showMessage('Session logged out', '', 'error');
        this.router.navigate(['/account/login']);
        this.ngxLoaderService.stop();
      }
    });
  }


}
